import React, { useEffect } from 'react'
import Homecss from '../Styles/Home.module.css'
import { TbFileDownload } from "react-icons/tb";
import { ImGithub } from "react-icons/im";
import { ImLinkedin } from "react-icons/im";
import Aos from 'aos'
import 'aos/dist/aos.css'
const Home = () => {

    useEffect(() => {
        Aos.init({ duratrion: 2000 })
    }, [])

    return (
        <>
            <div className={Homecss.container}>
                <div className={Homecss.left}>
                    <div className={Homecss.leftup} data-aos="fade-right" data-aos-delay="400">
                        <div className={Homecss.text1}>Full-Stack-Developer</div>
                        <div className={Homecss.text2}>Hello !</div>
                        <div className={Homecss.text3}>I'm Gaurav</div>
                        <div className={Homecss.text4}>I'm a developer with experience in building <b>Full-stack websites </b>for individuals and organizations.</div>
                        <div className={Homecss.text5}><span className={Homecss.forunder}>CHECK MY RESUME</span>
                            <a className={Homecss.resume} target='blank' href="https://drive.google.com/file/d/1hkQko184rRjuTzuuUEXR54BjfEXb1Q-j/view?usp=sharing"
                                Download="Gaurav-Resume"><TbFileDownload /></a>
                        </div></div>
                    <div className={Homecss.leftbottom} data-aos="fade-up" data-aos-delay="1200"  >
                        <a href='https://github.com/gaurav-12agrawal' target='blank' className={Homecss.iconlink}>   <p className={Homecss.homeicon}><span className={Homecss.homeiconmain}> <ImGithub /></span> Github</p></a>
                        <a href='https://www.linkedin.com/in/gaurav-agrawal-45119722a/' target='blank' className={Homecss.iconlink}>  <p className={Homecss.homeicon}><span className={Homecss.homeiconmain}><ImLinkedin /></span> Linked in</p></a>

                    </div>

                </div>
                <div className={Homecss.right} data-aos="fade-left" data-aos-delay="800"><img className={Homecss.pic} src='https://res.cloudinary.com/djy9ahahh/image/upload/v1685998984/important/IMG20221120231133-removebg-preview_h4kbpa.png' alt='My-pic'></img></div>
            </div>
        </>
    )
}

export default Home
