import React, { useEffect } from 'react'
import Aboutcss from '../Styles/About.module.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { HiOutlineChevronRight } from "react-icons/hi";
import { SiLeetcode } from "react-icons/si";
import Typewriter from 'typewriter-effect'
const About = () => {
    useEffect(() => {
        Aos.init()
    }, [])
    return (
        <>  <div className={Aboutcss.container} data-aos="zoom-in" data-aos-delay="100" >
            <div className={Aboutcss.first}>
                <p className={Aboutcss.firstheading}>
                    About</p>
                <p className={Aboutcss.firstline}></p></div>
            <p className={Aboutcss.second}>Know More About Me</p>
            <div className={Aboutcss.third}>
                <div className={Aboutcss.thirdleft} data-aos="fade-left" data-aos-delay="300">
                    <img src='https://res.cloudinary.com/djy9ahahh/image/upload/v1685860645/20230226_084201_ohblnh.jpg' alt='my-pic' className={Aboutcss.aboutpic}></img>
                </div>
                <div className={Aboutcss.thirdright} data-aos="fade-right" data-aos-delay="400">
                    <div className={Aboutcss.thirdright1}>
                        <Typewriter
                            options={{
                                strings: ['Gaurav Agrawal', 'Full Stack Developer', 'Competitive Programmer'],
                                autoStart: true,
                                loop: true,
                                delay: 100
                            }} />

                    </div>
                    <div className={Aboutcss.thirdright2}>Pursuing B. Tech at LNMIIT Jaipur in Computer and Communication Branch , 3rd Year Student.</div>
                    <div className={Aboutcss.thirdright3}>
                        <div className={Aboutcss.thirdright3a}>
                            <p> <span className={Aboutcss.arrow}> < HiOutlineChevronRight /></span> <span className={Aboutcss.thirdheading}> Website :</span> <span className={Aboutcss.thirdcontent}>https://gaurav-agrawal.co.in </span></p>
                            <p><span className={Aboutcss.arrow}>< HiOutlineChevronRight /></span> <span className={Aboutcss.thirdheading}>Gmail :</span> <span className={Aboutcss.thirdcontent}>gauravagrawal1202@gmail.com</span></p>
                            <p><span className={Aboutcss.arrow}> < HiOutlineChevronRight /></span> <span className={Aboutcss.thirdheading}>Phone : </span><span className={Aboutcss.thirdcontent}>+91-8290402653</span></p>

                        </div>
                        <div className={Aboutcss.thirdright3b}>
                            <p><span className={Aboutcss.arrow}> < HiOutlineChevronRight /></span><span className={Aboutcss.thirdheading}>Age :</span><span className={Aboutcss.thirdcontent}> 22</span></p>
                            <p> <span className={Aboutcss.arrow}>< HiOutlineChevronRight /></span><span className={Aboutcss.thirdheading}>College :</span><span className={Aboutcss.thirdcontent}> LNMIIT (B-tech)</span></p>
                            <p><span className={Aboutcss.arrow} >< HiOutlineChevronRight /></span><span className={Aboutcss.thirdheading}>City : </span><span className={Aboutcss.thirdcontent}>Hindaun, Rajasthan, India</span></p>
                        </div>
                    </div>
                    <div className={Aboutcss.thirdright4}>Experienced full stack developer proficient in MERN stack and highly skilled in competitive programming using C++. Passionate about crafting efficient and scalable solutions. Strong problem-solving abilities and a dedication to delivering high-quality code.Driven by a relentless pursuit of innovation, I strive to create seamless user experiences and optimize performance, leveraging my expertise in MERN stack development and competitive programming with C++.</div>
                </div>
            </div>
            <div className={Aboutcss.forth}>

                <div className={Aboutcss.forthleft} data-aos="fade-left" data-aos-delay="300">
                    <div className={Aboutcss.forthleft1}>
                        Education
                    </div>
                    <div className={Aboutcss.timeline}>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>B.TECH COMPUTER AND COMMUNICATION
                            <div className={Aboutcss.leftiteminner}>
                                <p className={Aboutcss.leftiteminnercontent1}>2021-present</p>
                                <p className={Aboutcss.leftiteminnercontent2}>The LNM Institute of informational Technology, Jaipur, India</p>
                                <p className={Aboutcss.leftiteminnercontent2}>CGPA (upto 6th sem) : 8.09</p>
                            </div></div>

                        </div>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>SENIOR SECONDARY (XII)
                            <div className={Aboutcss.leftiteminner}>
                                <p className={Aboutcss.leftiteminnercontent1}>2017-2018</p>
                                <p className={Aboutcss.leftiteminnercontent2}>Sarvodaya Senior Secondary School, karauli, Rajasthan</p>
                                <p className={Aboutcss.leftiteminnercontent2}>Percentage : 90.80</p>
                            </div></div>

                        </div>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>SECONDARY (X)
                            <div className={Aboutcss.leftiteminner}>
                                <p className={Aboutcss.leftiteminnercontent1}>2019-2020</p>
                                <p className={Aboutcss.leftiteminnercontent2}>P.S.C. Foundation Secondary School, karauli, Rajasthan </p>
                                <p className={Aboutcss.leftiteminnercontent2}>Percentage : 93.17</p>
                            </div></div>

                        </div>

                    </div>

                </div>
                <div className={Aboutcss.forthright} data-aos="fade-right" data-aos-delay="400">

                    <div className={Aboutcss.forthleft1}>
                        Skills
                    </div>
                    <div className={Aboutcss.timeline}>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>LANGUAGES
                            <div className={Aboutcss.rightiteminner}>
                                <p className={Aboutcss.rightiteminnercontent1}>C</p>
                                <p className={Aboutcss.rightiteminnercontent1}>CPP</p>
                                <p className={Aboutcss.rightiteminnercontent1}>JAVASCRIPT</p>
                                <p className={Aboutcss.rightiteminnercontent1}>PYTHON</p>
                                <p className={Aboutcss.rightiteminnercontent1}>JAVA</p>
                                <p className={Aboutcss.rightiteminnercontent1}>HTML</p>
                                <p className={Aboutcss.rightiteminnercontent1}>CSS</p>
                            </div></div>

                        </div>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>FRAMEWORKS
                            <div className={Aboutcss.rightiteminner}>
                                <p className={Aboutcss.rightiteminnercontent1}>REACTJS</p>
                                <p className={Aboutcss.rightiteminnercontent1}>NODEJS</p>
                                <p className={Aboutcss.rightiteminnercontent1}>EXPRESSJS</p>
                            </div></div>

                        </div>
                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>PLATFORMS & TOOLS
                            <div className={Aboutcss.rightiteminner}>
                                <p className={Aboutcss.rightiteminnercontent1}>GIT</p>
                                <p className={Aboutcss.rightiteminnercontent1}>GITHUB</p>
                                <p className={Aboutcss.rightiteminnercontent1}>RENDER</p>
                                <p className={Aboutcss.rightiteminnercontent1}>CLOUDINARY</p>
                                <p className={Aboutcss.rightiteminnercontent1}>NETLIFY</p>
                                <p className={Aboutcss.rightiteminnercontent1}>POSTMAN</p>
                            </div></div>

                        </div>

                        <div className={Aboutcss.leftitem} ><div className={Aboutcss.leftitemheading}>DATABASES
                            <div className={Aboutcss.rightiteminner}>
                                <p className={Aboutcss.rightiteminnercontent1}>MONGODB</p>
                                <p className={Aboutcss.rightiteminnercontent1}>MYSQL</p>

                            </div></div>

                        </div>

                    </div>
                </div>
            </div>
            <div className={Aboutcss.bottomdiv}>
                <div className={Aboutcss.fifth} data-aos="fade-left" >
                    <p className={Aboutcss.fifthleft1}>EXTRA CURRICULAR ACTIVITIES</p>
                    <p className={Aboutcss.extraitem}> <span className={Aboutcss.arrowE}> < HiOutlineChevronRight /></span> Core Member in development domain @ <b> GDSC (Google Developer Student Club)</b></p>
                    <p className={Aboutcss.extraitem}> <span className={Aboutcss.arrowE}> < HiOutlineChevronRight /></span> Member @ LNMIIT Robotics club <b>PHOENIX</b></p>
                    <p className={Aboutcss.extraitem}> <span className={Aboutcss.arrowE}> < HiOutlineChevronRight /></span> Member @ LNMIIT Social club <b>SANKALP</b></p>
                </div>
                <div className={Aboutcss.fifth} data-aos="fade-right" >
                    <p className={Aboutcss.fifthleft1}>ACHIEVEMENTS</p>
                    <p className={Aboutcss.extraitem}><span className={Aboutcss.arrowE}>< HiOutlineChevronRight /></span>Knight at Leetcode, Top 6% worldwide | Max. Rating 1853 |<a href="https://leetcode.com/u/Technanite/" target="_blank" >
                        <img className={Aboutcss.leetcodelogo} src='https://res.cloudinary.com/djy9ahahh/image/upload/v1724356023/leetcode_eqdalu.png' alt="LeetCode Logo" />
                    </a>
                    </p>
                    <p className={Aboutcss.extraitem}><span className={Aboutcss.arrowE}>< HiOutlineChevronRight /></span>Pupil on Codeforces, 300+ problem solve | Max. Rating 1206 |<a href="https://codeforces.com/profile/gaurav_1202" target="_blank" >
                        <img className={Aboutcss.leetcodelogo} src='https://res.cloudinary.com/djy9ahahh/image/upload/v1724363426/codeforces_vroaaw.png' alt="LeetCode Logo" />
                    </a>
                    </p>
                    <p className={Aboutcss.extraitem}><span className={Aboutcss.arrowE}>< HiOutlineChevronRight /></span>Ranked 2049 out of 36161 participants worldwide in LeetCode Weekly Contest 410</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default About
