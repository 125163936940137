import React, { useEffect } from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Contactcss from '../Styles/Contact.module.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { SlLocationPin } from "react-icons/sl";
import { AiOutlineMail, AiOutlineShareAlt } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { ImGithub } from "react-icons/im";
import { ImLinkedin } from "react-icons/im";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_7rfs53l', 'template_vn14vs8', form.current, 'ONLn9GI6HBlOjJlkT')
            .then((result) => {
                return toast.success("Message Sent Successfully", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })

            }, (error) => {
                console.log(error);
                return toast.success("Message not sent ! please try after some time", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })

            });
    };
    useEffect(() => {
        Aos.init()
    }, [])
    return (
        <>
            <ToastContainer
                position="top-center" />
            <div className={Contactcss.container} data-aos="zoom-in" data-aos-delay="100" >
                <div className={Contactcss.first}>
                    <p className={Contactcss.firstheading}>
                        Contact</p>
                    <p className={Contactcss.firstline}></p></div>
                <p className={Contactcss.second}>Contact Me</p>
                <div className={Contactcss.third}>
                    <div className={Contactcss.third1}>
                        <form ref={form} onSubmit={sendEmail} data-aos="flip-right" data-aos-delay="400"  >
                            <div className={Contactcss.form1}>
                                <div className={Contactcss.eachinputdiv1} >
                                    <label htmlFor='name'>Name</label>
                                    <input type='text' id='name' required name='name' ></input>
                                </div>
                                <div className={Contactcss.eachinputdiv2} >
                                    <label htmlFor='email'>Email</label>
                                    <input type='email' id='email' required name='email' ></input>
                                </div>
                            </div>
                            <div className={Contactcss.form2}>
                                <div className={Contactcss.eachinputdiv} >
                                    <label htmlFor='subject'>Subject</label>
                                    <input type='text' id='subject' required name='subject' ></input>
                                </div>
                            </div>
                            <div className={Contactcss.form3}>
                                <div className={Contactcss.eachinputdiv} >
                                    <label htmlFor='message'>Message</label>
                                    <textarea id='message' required name='message' rows='10' ></textarea>
                                </div>
                            </div>
                            <button type='submit' className={Contactcss.button}>Send Message</button>
                        </form>
                    </div>
                    <div className={Contactcss.third2}>
                        <div className={Contactcss.third2_1} >

                            <div className={Contactcss.itemdown}>
                                <div className={Contactcss.itemdownicon}> <SlLocationPin /> <span className={Contactcss.itemdownh}> MY Address</span></div>
                                <p className={Contactcss.itemcontent}>Hindaun City, Karauli, Rajasthan, India</p></div>
                        </div>
                        <div className={Contactcss.third2_1}>

                            <div className={Contactcss.itemdown}>
                                <div className={Contactcss.itemdownicon}>  <AiOutlineMail /> <span className={Contactcss.itemdownh}> MY Email</span></div>
                                <p className={Contactcss.itemcontent}>gauravagrawal1202@gmail.com</p></div>
                        </div>
                        <div className={Contactcss.third2_1}>

                            <div className={Contactcss.itemdown}>
                                <div className={Contactcss.itemdownicon}> <BiPhoneCall /> <span className={Contactcss.itemdownh}> Call Me</span></div>
                                <p className={Contactcss.itemcontent}>+91-8290402653</p></div>
                        </div>


                    </div>
                    <div className={Contactcss.third3}>
                        <div className={Contactcss.third3_1}><AiOutlineShareAlt className={Contactcss.share} /> Social Links</div>
                        <div className={Contactcss.socialicons}>
                            <a href='https://www.instagram.com/gauru_12_ag/' target='blank'><div className={Contactcss.social}><FaInstagram /></div></a>
                            <a href='https://www.facebook.com/profile.php?id=100068808022538' target='blank'><div className={Contactcss.social}><FaFacebookSquare /></div></a>
                            <a href='https://www.linkedin.com/in/gaurav-agrawal-45119722a/' target='blank' ><div className={Contactcss.social}><ImLinkedin /></div></a>
                            <a href='https://github.com/gaurav-12agrawal' target='blank'><div className={Contactcss.social}>< ImGithub /></div></a>
                        </div>
                        <div> </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
