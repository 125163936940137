import React, { useEffect } from 'react'
import Projectcss from '../Styles/Project.module.css'
import Card from './Card'
import Aos from 'aos'
import Data from './Projectdata'
import 'aos/dist/aos.css'
import '../App.css'
const Project = () => {
    useEffect(() => {
        Aos.init()
    }, [])
    let reversedArray = [...Data].reverse();
    return (
        <div className={Projectcss.container} data-aos="zoom-in" data-aos-delay="100">
            <div class="container1">
                <a href="https://drive.google.com/file/d/1hkQko184rRjuTzuuUEXR54BjfEXb1Q-j/view?usp=sharing"
                    Download="Gaurav-Resume"
                    target='blank'
                >
                    <button class="learn-more">
                        <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                        </span>
                        <span class="button-text">Download Resume</span>
                    </button>
                </a>

            </div>
            <div >
                <div className={Projectcss.first}>
                    <p className={Projectcss.firstheading}>
                        Experience</p>
                    <p className={Projectcss.firstline}></p>
                </div>
                <p className={Projectcss.second}>MY Experience</p>
                <div className='expcontainer'>
                    <div className={Projectcss.third1} >
                        <div className={Projectcss.griddiv1}>
                            <div className={Projectcss.maincard1} data-aos="zoom-in" data-aos-delay="300">
                                <p className={Projectcss.exp1}>Summer Intern @ Miimanasa ,Remote | Software Engineer</p>
                                <p className={Projectcss.exp2}>27 june 2023 - 31 july 2024</p>
                                <p className={Projectcss.exp3}>Working as a Software Engineer to provide frontend and backend services to clients. </p>
                                <p className={Projectcss.exp3}>CERTIFICATE: <a target='blank' href='https://Miimansa.com'>Not yet received</a></p>

                            </div>
                            <div className={Projectcss.maincard1} data-aos="zoom-in" data-aos-delay="300">
                                <p className={Projectcss.exp1}>Intern @ Infenito Technology, Remote | Frontend  Developer</p>
                                <p className={Projectcss.exp2}>14 july 2023 - 14 October 2023 </p>
                                <p className={Projectcss.exp3}>Working on Figma plugin development as a frontend developer. </p>
                                <p className={Projectcss.exp3}>CERTIFICATE: <a target='blank' href='https://drive.google.com/file/d/14Yb6x8Xt85smpwLbX2OdEFJo_nyhXPLj/view'>HERE</a></p>
                            </div>
                            <div className={Projectcss.maincard1} data-aos="zoom-in" data-aos-delay="300">
                                <p className={Projectcss.exp1}>LNMHACKS 5.0 | Hackathon organized by LNMIIT</p>
                                <p className={Projectcss.exp2}>In this hackathon I developed a clone of chatgpt with team of three developer
                                    using Open Ai Api and javascript . </p>
                                <p className={Projectcss.exp3}>Repo Link : <a href='https://github.com/gaurav-12agrawal/LNM_GPT'>LNM_GPT</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Projectcss.first}>
                <p className={Projectcss.firstheading}>
                    Projects</p>
                <p className={Projectcss.firstline}></p></div>
            <p className={Projectcss.second}>MY Projects</p>
            <div className={Projectcss.third}>
                <div className={Projectcss.griddiv}>
                    {
                        (
                            reversedArray.map((value, id) => {

                                return (
                                    <>
                                        <div key={id + 1} >
                                            <Card details={value} />
                                        </div>
                                    </>
                                )
                            }))

                    }
                </div>
            </div>
        </div >
    )
}

export default Project
