import React, { useEffect } from 'react'
import Projectcss from '../Styles/Project.module.css'
import { ImGithub } from "react-icons/im";
import { BiLink } from "react-icons/bi";
import Aos from 'aos'
import 'aos/dist/aos.css'
const Project = () => {
    useEffect(() => {
        Aos.init()
    }, [])
}
const Card = (props) => {

    return (
        <>
            <div className={Projectcss.maincard} data-aos="zoom-in" data-aos-delay="300">
                <img className={Projectcss.card1} src={props.details.img}></img>
                <div className={Projectcss.card2}>{props.details.title}</div>
                <div className={Projectcss.card3}>{props.details.content}</div>
                <div className={Projectcss.card4}><b>TECH STACK : {props.details.keyword} </b></div>
                <div className={Projectcss.card5}>
                    {props.details.islive ? <a href={props.details.livelink} target='blank'> <button className={Projectcss.cardbutton}><BiLink /> Live Preview</button></a> :

                        <button className={Projectcss.cardbuttonno}><BiLink /> Live Preview</button>}
                    {props.details.iscode ? <a href={props.details.codelink} target='blank'><button className={Projectcss.cardbutton}><ImGithub /> View Code</button></a> :
                        <button className={Projectcss.cardbuttonno}><ImGithub /> View Code</button>}
                </div>

            </div>
        </>
    )
}

export default Card
