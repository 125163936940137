import React, { useEffect, useState } from 'react'
import Navcss from '../Styles/Navbar.module.css'
import Aos from 'aos'
import { SlMenu } from "react-icons/sl";
import { AiOutlineClose } from "react-icons/ai";
import 'aos/dist/aos.css'
const Navbar = (props) => {
    const [hide, sethide] = useState(true)
    const [bar, setbar] = useState(false)
    const [nav1, setnav1] = useState(true)
    const [nav2, setnav2] = useState(false)
    const [nav3, setnav3] = useState(false)
    const [nav4, setnav4] = useState(false)

    useEffect(() => {
        Aos.init({ duratrion: 2000 })
    }, [])
    const active1 = () => {

        props.forscroll(1);
        setnav1(true)
        setnav2(false)
        setnav3(false)
        setnav4(false)
        setbar(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
    }
    const active2 = () => {
        props.forscroll(2);
        setbar(false)
        setnav1(false)
        setnav2(true)
        setnav3(false)
        setnav4(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
    }
    const active3 = () => {
        props.forscroll(3);
        setbar(false)
        setnav1(false)
        setnav2(false)
        setnav3(true)
        setnav4(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
    }
    const active4 = () => {
        setbar(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
        props.forscroll(4);
        setnav1(false)
        setnav2(false)
        setnav3(false)
        setnav4(true)
    }

    const active5 = () => {
        window.open("mailto:gauravagrawal1202@gmail.com");
        setbar(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
    }
    const barfunction = () => {
        sethide(false)
        setTimeout(() => {
            setbar(true)
        }, 100);
    }
    const closefunction = () => {
        setbar(false)
        setTimeout(() => {
            sethide(true)
        }, 300);
    }
    return (
        <>
            <div className={Navcss.container} data-aos="fade-down" data-aos-delay="200" >
                <div className={Navcss.containerleft}>GAURAV AGRAWAL</div>
                <div className={` ${hide && Navcss.hide} ${bar && Navcss.toggle} ${Navcss.containerright}`}>
                    <li className={`${nav1 && Navcss.active} ${Navcss.item}`} onClick={active1}>Home</li>
                    <li className={`${nav2 && Navcss.active}  ${Navcss.item}`} onClick={active2}>About</li>
                    <li className={`${nav3 && Navcss.active}  ${Navcss.item}`} onClick={active3}>Resume</li>
                    <li className={`${nav4 && Navcss.active}  ${Navcss.item}`} onClick={active4}>Contact</li>
                    <li className={Navcss.item1} onClick={active5}>Hire me</li>

                </div >
                <div className={Navcss.bar} >
                    {bar ? <AiOutlineClose onClick={closefunction} /> : <SlMenu onClick={barfunction} />}
                </div>
            </div >
        </>
    )
}

export default Navbar
