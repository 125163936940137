import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import './App.css';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react';
import Contact from './components/Contact';
import Project from './components/Project';
import Preloader from './components/Preloader';
import { motion } from 'framer-motion';

function App() {
  const [item, setitem] = useState(1)
  const [load, setload] = useState(true)
  const handleclick = (num) => {
    setitem(num)
  }
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });
  const [cursorVariant, setCursorVariant] = useState("default");
  useEffect(() => {
    setTimeout(() => {
      setload(false)
    }, 4000);
    Aos.init()

    const mouseMove = e => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    }
  }, [])
  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 210,
      backgroundColor: "#59e1ac",
      mixBlendMode: "difference"
    }
  }
  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");
  return (
    <>
      <motion.div
        className='cursor'
        variants={variants}
        animate={cursorVariant}
      />
      <div>

        <div>{
          load ? <Preloader /> :
            <div>
              <Navbar forscroll={handleclick} />
              <div  >{item === 1 ? <Home /> : <></>}</div>
              <div>{item === 2 ? <About /> : <></>}</div>
              <div>{item === 3 ? <Project /> : <></>}</div>
              <div>{item === 4 ? <Contact /> : <></>}</div>
            </div>}
        </div>

      </div>

    </>
  );
}

export default App;
