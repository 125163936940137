const Projectdata = [
    {
        id: 2,
        img: "https://res.cloudinary.com/djy9ahahh/image/upload/v1686064089/important/Untitled_design_ewadmr.png",
        title: "Frontend Designs",
        content: "There are some frontend projects whlile i was learning development and some of them i created for  client",
        keyword: " HTML,   CSS,  JavaScript, Bootstrap  ",
        islive: false,
        iscode: true,
        codelink: "https://github.com/gaurav-12agrawal?tab=repositories",
        livelink: ""
    },
    {
        id: 3,
        img: "https://res.cloudinary.com/djy9ahahh/image/upload/v1685910055/Screenshot_1632_hwjwfg.png",
        title: "Weather App",
        content: "This is a simple javascript project using fetch api. This was my first project for Api's.  code and preview are avialable for this",
        keyword: " HTML,   CSS,  JavaScript, unsplash-random-images ",
        islive: true,
        iscode: true,
        codelink: "https://github.com/gaurav-12agrawal/Weather-App",
        livelink: "https://gaurav-12agrawal.github.io/Weather-App/"

    },
    {
        id: 4,
        img: "https://res.cloudinary.com/djy9ahahh/image/upload/v1685904993/important/phoenix2_m6armu.png",
        title: "PHOENIX",
        content: "I  developed  my institute's Robotics Club's website with a team of 3 developer. Code can't be shared due to security reasons.",
        keyword: " HTML,   CSS,  JavaScript,   React, Particlejs, github",
        islive: true,
        iscode: false,
        codelink: "",
        livelink: "https://phoenix.lnmiit.ac.in/"

    },
    {
        id: 5,
        img: "https://res.cloudinary.com/djy9ahahh/image/upload/v1685904402/important/Screenshot_1630_x42zvo.png",
        title: "GYMOZY",
        content: "As a full stack plateform, I designed and developed a dynamic gym booking site.",
        keyword: "Reactjs, Nodejs, Express, Mongodb, Javascript, Nodemailer",
        islive: true,
        iscode: false,
        codelink: "",
        livelink: "https://gymozy.netlify.app/"

    },
    {
        id: 6,
        img: "https://res.cloudinary.com/djy9ahahh/image/upload/v1724354883/Screenshot_2024-08-23_005720_hu31sm.png",
        title: "LearnHub",
        content: "Developed an online learning platform with MERN stack, including feedback and course hierarchy.",
        keyword: "ReactJS, NodeJS, Mongodb, ExpressJS, JavaScript,Redux, flask, LLM API",
        islive: false,
        iscode: true,
        codelink: "https://github.com/BtpOfficial/BTP",
        livelink: ""

    }
]
export default Projectdata