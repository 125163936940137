import React from 'react'
import Typewriter from 'typewriter-effect'
import Precss from '../Styles/Loader.module.css'
const Preloader = () => {
    return (
        <div className={Precss.container}>
            <Typewriter
                options={{
                    strings: [' Meet Gaurav Agrawal'],
                    autoStart: true,
                    loop: true,
                    delay: 100
                }} />
        </div>
    )
}

export default Preloader
